import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  DamageReportFormContextType,
  StepOne,
  StepThree,
  StepTwo,
} from './damage-report-form.types';

import {
  validateEmailAddress,
  validatePhoneNumber,
} from 'pages/DamageReportForm/form-navigation/validations';

const DamageReportFormContext = createContext<DamageReportFormContextType | null>(null);

export function DamageReportFormProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const steps = useMemo(
    () => [
      t('damage_report_form.steps.one'),
      t('damage_report_form.steps.two'),
      t('damage_report_form.steps.three'),
      t('damage_report_form.steps.four'),
    ],
    [t]
  );
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);

  const [stepOne, setStepOne] = useState<StepOne>({
    description: '',
    damageCategory: '',
    poleDamageType: '',
    damage: {
      fiber: '',
      copper: '',
    },
    cabinetType: '',
    cableWidth: '',
    isGuideCorrect: undefined,
    damageType: '',
    managementControlCaseNumber: '',
    dateOfIncident: undefined,
    dateOfRefill: undefined,
    dateOfIncidentDisabled: false,
  });

  const [stepTwo, setStepTwo] = useState<StepTwo>({
    address: '',
    position: {
      latitude: undefined,
      longitude: undefined,
    },
    description: '',
  });

  const [stepThree, setStepThree] = useState<StepThree>({
    author: {
      firstName: '',
      lastName: '',
      organization: '',
      phone: '+46',
      email: '',
    },
    onSite: {
      isSameAsAuthor: true,
      firstName: '',
      lastName: '',
      organization: '',
      phone: '+46',
      email: '',
    },
  });

  const validateFormStep = useCallback(
    (step: number) => {
      switch (step) {
        case 1: {
          switch (stepOne.damageCategory) {
            case 'GROUND_CABLE': {
              if (
                stepOne.damageCategory &&
                stepOne.description &&
                stepOne.dateOfIncident &&
                stepOne.isGuideCorrect
              ) {
                return true;
              }
              return false;
            }
            case 'AIR_CABLE': {
              if (
                stepOne.damageCategory &&
                stepOne.description &&
                stepOne.dateOfIncident &&
                stepOne.isGuideCorrect
              ) {
                return true;
              }
              return false;
            }
            case 'CABINET': {
              if (
                stepOne.damageCategory &&
                stepOne.description &&
                stepOne.dateOfIncident &&
                stepOne.isGuideCorrect
              ) {
                return true;
              }
              return false;
            }

            case 'TECH_BUILDING': {
              return false;
            }

            case 'POLE': {
              if (
                stepOne.damageCategory &&
                stepOne.description &&
                stepOne.dateOfIncident &&
                stepOne.isGuideCorrect
              ) {
                return true;
              }
              return false;
            }

            case 'WELL': {
              return false;
            }

            default:
              return false;
          }
        }

        case 2: {
          if (
            stepTwo.description.trim().length &&
            stepTwo.address.trim().length &&
            typeof stepTwo.position.latitude !== 'undefined' &&
            typeof stepTwo.position.longitude !== 'undefined'
          ) {
            return true;
          }
          return false;
        }

        case 3: {
          const { author, onSite } = stepThree;

          if (
            author.firstName.trim().length &&
            author.lastName.trim().length &&
            validatePhoneNumber(author.phone) &&
            validateEmailAddress(author.email)
          ) {
            if (onSite.isSameAsAuthor) return true;

            if (
              onSite.firstName.trim().length &&
              onSite.lastName.trim().length &&
              validatePhoneNumber(onSite.phone) &&
              validateEmailAddress(onSite.email)
            ) {
              return true;
            }
            return false;
          }
          return false;
        }

        case 4: {
          if (validateFormStep(1) && validateFormStep(2) && validateFormStep(3)) {
            return true;
          }
          return false;
        }

        default: {
          return false;
        }
      }
    },
    [stepOne, stepTwo, stepThree]
  );

  const value = useMemo(
    () => ({
      steps,

      activeStep,
      setActiveStep,

      validateFormStep,

      isSendingRequest,
      setIsSendingRequest,

      selectedFiles,
      setSelectedFiles,

      stepOne,
      setStepOne,

      stepTwo,
      setStepTwo,

      stepThree,
      setStepThree,
    }),
    [
      stepOne,
      stepTwo,
      stepThree,
      steps,
      activeStep,
      setActiveStep,
      validateFormStep,
      selectedFiles,
      isSendingRequest,
    ]
  );

  return (
    <DamageReportFormContext.Provider value={value}>{children}</DamageReportFormContext.Provider>
  );
}

export const useDamageReportForm = (): DamageReportFormContextType =>
  useContext(DamageReportFormContext) as DamageReportFormContextType;
