import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from 'components/voca/heading/Heading';
import './FileUpload.scss';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

interface FileUploadProps {
  isExpanded: boolean;
}

const SIZE_50MB = 50 * 1024 * 1024;
const IMAGE_JPEG = 'image/jpeg';
const IMAGE_PNG = 'image/png';
const ALLOWED_FILE_TYPES = [IMAGE_JPEG, IMAGE_PNG];
const ALLOWED_FILE_TYPES_HTML = '.jpg, .png';

export default function FileUpload({ isExpanded }: FileUploadProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { selectedFiles, setSelectedFiles } = useDamageReportForm();

  function calculateTotalSize(files: File[]): number {
    return files.reduce((totalSize, file) => totalSize + file.size, 0);
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const newFiles = Array.from(e.target.files as FileList);
    const totalSize = calculateTotalSize([...selectedFiles, ...newFiles]);

    if (totalSize > SIZE_50MB) {
      setErrorMessage(t('error_message.size'));
      return;
    }

    const validFiles = newFiles.filter((file: File) => {
      const isImage = ALLOWED_FILE_TYPES.includes(file.type);

      if (!isImage) {
        setErrorMessage(t('error_message.type'));
      }

      return isImage;
    });

    // TODO: call this function in test env only!
    if (process.env.REACT_APP_HOST && process.env.REACT_APP_HOST.includes('test')) {
      uploadImageToServer(validFiles);
    }

    setSelectedFiles([...selectedFiles, ...validFiles]);
  }

  function removeFile(index: number) {
    const updatedFiles = [...selectedFiles];

    updatedFiles.splice(index, 1);

    setSelectedFiles(updatedFiles);
    setErrorMessage('');
  }

  function initNativeFileInputClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      errorMessage && setErrorMessage('');
    }
  }

  function getFileSizeInMB(sizeInBytes: number): string {
    let sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 0 && sizeInMB < 0.01) {
      sizeInMB = 0.01;
    }
    return `${sizeInMB.toFixed(2)} mb`;
  }

  /* eslint-disable */
  // TODO: it's POC
  function uploadImageToServer(files: File[]) {
    // Making the API request for each valid file
    files.forEach(async (file) => {
      const randomNumber = Math.floor(Math.random() * 1000); // Generate random number
      const fileName = file.name;
      const fileContentType = file.type;

      try {
        const response = await fetch(`https://test.natet.telia.se/api/report?fileName=${encodeURIComponent(fileName)}&id=${randomNumber}&contentType=${encodeURIComponent(fileContentType)}`, {
          method: 'GET',
        });

        const data = await response.json();
        console.log('Response:', data);

        const uploadUrl = data.url; // Extract URL from the response

        // Step 2: Make PUT request to the extracted URL to upload the file
        const putResponse = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type, // Set the content type of the file
          },
          body: file, // The file to be uploaded
        });

        if (!putResponse.ok) {
          throw new Error('Failed to upload the file');
        }

        console.log('File uploaded successfully:', fileName);
      } catch (error) {
        console.error('Error during file upload:', error);
      }

    });
  }

  return (
    <div title="upload" className={`file-upload ${isExpanded ? 'file-upload--expanded' : ''}`}>
      <Heading className="file-upload__heading" variant="title-100" tag="h2">
        {t('damage_report_form.upload')}
      </Heading>

      <input
        title="input"
        type="file"
        accept={ALLOWED_FILE_TYPES_HTML}
        onChange={handleFileChange}
        ref={fileInputRef}
      />

      {errorMessage && <p className="file-upload__error-message">{errorMessage}</p>}

      <ul>
        {selectedFiles.map((file, index) => (
          <li key={index}>
            <TeliaIcon
              dataTestid="remove-button"
              className="icon"
              size="md"
              name="delete"
              onClick={() => removeFile(index)}
            />
            <span className="file-name">{file.name}</span>
            <span className="file-size">{getFileSizeInMB(file.size)}</span>
          </li>
        ))}
      </ul>

      <div className="file-upload__button">
        <TeliaButton variant="text" size="md" onClick={initNativeFileInputClick}>
          <TeliaIcon slot="left" size="sm" name="plus" />
          {t('action.upload')}
        </TeliaButton>
      </div>

      <p className="file-upload__max-message">Max 50 MB</p>
    </div>
  );
}
